
import { computed, defineComponent, ref, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import CloserController from '@/components/molecules/CloserController.vue'
import AppButton from '../atoms/AppButton.vue'
import AppIconPlaces from '../atoms/AppIconPlaces.vue'
import AppInput from '../atoms/AppInput.vue'
import AppCheckbox from '../atoms/AppCheckbox.vue'
import AppLabelPlaces from '../atoms/AppLabelPlaces.vue'
import AppTagAddition from '../atoms/AppTagAddition.vue'
import DssIcon from '../atoms/DssIcon.vue'
import PlaceholderImage from '../atoms/PlaceholderImage.vue'
import ButtonIcon from '../molecules/ButtonIcon.vue'
import ModalWindow from '../organisms/ModalWindow.vue'
import CollectionDropdown from './TheMyCollectionCollectionDropdown.vue'
import AppTag from './TheMyCollectionAppTag.vue'
import { useI18n } from 'vue-i18n'
import { ruleToType, getPermissionColor } from '@/data/Permission'
import { getAvailableStateText } from '@/data/AccessRestrictions'
import placeholderImageIcon from '@/domain/item/ItemListView/placeholderImageIcon'
import { useItemListViewTextOfMyCollection } from '@/domain/item/ItemListView'
import { getItemListViewOfMyCollectionAllCollectionsForSearch } from '@/domain/item/ItemListView/collectionLabel'
import { sessionCheck } from '@/domain/session/sessionCheck'

export default defineComponent({
  name: 'MyCollectionItemListView',
  components: {
    AppButton,
    AppCheckbox,
    AppLabelPlaces,
    AppIconPlaces,
    AppInput,
    AppTag,
    AppTagAddition,
    ButtonIcon,
    CloserController,
    CollectionDropdown,
    DssIcon,
    PlaceholderImage,
    ModalWindow,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isItem: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'div',
    },
    index: {
      type: Number,
      default: 0,
    },
    displayMode: {
      type: String,
      default: 'list',
    },
    contentListViewHandler: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

    const i18n = useI18n()
    const lang = i18n.locale
    const store = useStore()
    const route = useRoute()
    const isRekion = route.meta.isRekion
    const places = [
      { icon: '', text: '' },
      { icon: 'internet', text: i18n.t('permissionText.internet', { newLine: '<br>' }) },
      { icon: 'ooc', text: i18n.t('permissionText.ooc.isntLoggedIn', { newLine: '<br>' }) },
      { icon: 'inlibrary', text: i18n.t('permissionText.inlibrary', { newLine: '<br>' }) },
    ]

    const hasThumbnail = ref<boolean>(true)
    const notThumbnail = () => {
      hasThumbnail.value = false
    }

    const bookmarkItem = async (query: any) => {
      if (!await sessionCheck(route.fullPath)) return
      store.dispatch('myCollectionDelete', query)
    }

    const tagList = computed(() => {
      return store.getters.myCollectionTagList
    })

    const getTagText = (tagId: number): string => {
      return tagList.value.find((tag: any) => tag.id === tagId)?.label
    }

    const displayTagList = computed(() => {
      const TAG_ID_ALL = '0'
      const list = props.item.tagIds
      const filter = list.filter((v: number) => {
        return String(v) !== TAG_ID_ALL
      })

      return filter.length ? filter : false
    })

    const contentList = computed(() => {
      const contents = props.item.contents || []
      return contents.length ? contents : []
    })

    const contentsName = (name: any) => {
      if (!name) return ''
      return name.ja || ''
    }

    const onUnlink = async (tagId: number) => {
      if (!await sessionCheck(route.fullPath)) return
      store.dispatch('myCollectionItemUnlink', {
        tagId: tagId,
        pid: props.item.pid,
        bid: null,
        cid: null,
      })
    }

    const newLineCode = '\r\n'
    const permissionText = computed(() => getAvailableStateText(permissionRule.value, location.value, isLoggedIn.value, isMiddlePersend.value, newLineCode, i18n))

    const permissionRule = computed(() => {
      const rule = props.item.permission ? props.item.permission.rule ? props.item.permission.rule : 'internet' : ''
      return ruleToType(rule)
    })

    const permissionColor = computed(() => {
      return getPermissionColor(permissionRule.value, isRekion)
    })

    const isLoggedIn = computed(() => store.getters.isLoggedIn)
    const location = computed(() => store.getters.location)
    const isMiddlePersend = computed(() => store.getters.isMiddlePersend)

    const showContents = computed(() => {
      return props.contentListViewHandler
    })

    const thumbnailLink = (item: any) => {
      const VIEW_TYPE_LIST_ITEM = 'list'
      if (VIEW_TYPE_LIST_ITEM === props.displayMode) return `/pid/${item.pid}`
      if (item.isItem) {
        return `/pid/${item.pid}`
      }
      return `/pid/${item.contentLink}`
    }

    const { itemListViewContent, itemListViewTitle } = useItemListViewTextOfMyCollection(props.item, store, lang.value)

    /** コレクション一覧 */
    const itemListViewCollection = computed(() => {
      const collectionList = getItemListViewOfMyCollectionAllCollectionsForSearch(props.item, store, lang.value)
      if (!Array.isArray(collectionList)) return ''
      return collectionList.join('　') + '\r\n'
    })

    const placeholderType = computed(() => {
      const placeholderImage = placeholderImageIcon(store.getters.CollectionFamily)
      const collections: string[] = props.item.collections
      const type = props.item.type

      return placeholderImage(collections, type)
    })

    const isServer = typeof window === 'undefined'

    const changeTagSelected = () => {
      if (isServer) return

      const allItemsCount = document.querySelectorAll('input[name=multiple-add-tag]')?.length
      // アイテム行ごとのチェックボックスから選択済チェックボックスのみを取得
      const selectedTagsCount = document.querySelectorAll('input[name=multiple-add-tag]:checked')?.length
      // 全てを選択するチェックボックス状態を取得
      const allChecked: HTMLInputElement | null = document.querySelector('input[id=multiple-add-tag-all]')
      if (allChecked === null) return

      // 全てを選択するチェックボックス制御
      if (selectedTagsCount === allItemsCount) {
        allChecked.checked = true
      } else {
        allChecked.checked = false
      }
      // コレクションに追加ボタンを制御
      if (selectedTagsCount === 0) {
        store.dispatch('myCollectionShowCollectionDropdown', false)
      } else {
        store.dispatch('myCollectionShowCollectionDropdown', true)
      }
    }

    const itemIndex = props.index + 1

    const checkBoxAriaLabel = computed(() => {
      return i18n.t('mycollection.tag.check', { line: itemIndex })
    })

    return {
      BASE_URL,
      places,
      store,
      route,
      bookmarkItem,
      content: props.item.content,
      contentList,
      collections: false,
      displayTagList,
      indexHighlight: props.item.highlightFields,
      itemIndex,
      placeholderType,
      contentsName,
      getTagText,
      onUnlink,
      notThumbnail,
      hasThumbnail,
      permissionRule,
      permissionColor,
      permissionText,
      thumbnailLink,
      itemListViewContent,
      itemListViewTitle,
      itemListViewCollection,
      showContents,
      changeTagSelected,
      checkBoxAriaLabel,
    }
  },
})
