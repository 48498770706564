
import { defineComponent, onUnmounted } from 'vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import fixBackground from '@/helpers/util/fixBackground'
import { focusInsideDialog } from '@/helpers/util/focusInsideDialog'

export default defineComponent({
  components: {
    ButtonIcon,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    hasClose: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  setup (props, context) {
    const isServer = typeof window === 'undefined'

    const escape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        context.emit('close')
      }
    }

    fixBackground.fix()
    if (!isServer) {
      window.addEventListener('keydown', escape)
      onUnmounted(() => {
        fixBackground.cancel()
        window.removeEventListener('keydown', escape)
      })
    }

    focusInsideDialog('.modal-window')
  },
})
