
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: {
    DssIcon,
  },
  props: {},
  setup (props, context) {
    const store = useStore()
    const i18n = useI18n()
    const router = useRouter()
    const route = useRoute()

    const tagList = computed(() => {
      const myCollectionTagList = store.getters.myCollectionTagList
      if (myCollectionTagList.length > 1) {
        myCollectionTagList[0].label = i18n.t('mycollection.tag.all')
        myCollectionTagList[myCollectionTagList.length - 1].label = i18n.t('mycollection.tag.uncategorized')
      }
      return myCollectionTagList
    })

    const onTagSelected = (id: number) => {
      store.commit('MY_COLLECTION_SELECTED_TAG', id)
      context.emit('tagSelected')
      router.push({ query: { ...route.query, tagId: id, pageNum: 0 } })
    }

    const tagSelected = computed(() => store.getters.myCollectionSelectedTag)

    return {
      onTagSelected,
      tagSelected,
      tagList,
    }
  },
})
