
import { defineComponent, reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'

// const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL
import _ from 'lodash'
import AppButton from '../atoms/AppButton.vue'
import AppInput from '../atoms/AppInput.vue'
import AppTagAddition from '../atoms/AppTagAddition.vue'
import ButtonIcon from '../molecules/ButtonIcon.vue'
import ModalWindow from '../organisms/TheMyCollectionModalWindow.vue'
import { sessionCheck } from '@/domain/session/sessionCheck'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'MyCollectionCollectionDropdown',
  components: {
    AppButton,
    AppInput,
    AppTagAddition,
    ButtonIcon,
    ModalWindow,
  },
  emits: ['update'],
  props: {
    pid: {
      type: Number,
      default: 0,
    },
    styled: {
      type: String,
      default: 'is-default', // or is-none
    },
    selectedTag: {
      type: Array,
    },
  },
  setup (props, context) {
    const store = useStore()
    const route = useRoute()
    const isServer = typeof window === 'undefined'

    const state = reactive({
      isShowModalSetTag: false,
      isShowModalAddTag: false,
      isShowAddTagOption: false,
      isShowModalNewTag: false,
      newTagName: '',
    })

    const addTagDropdownOption = ref()

    const onShowAddTagOption = () => {
      state.isShowAddTagOption = !state.isShowAddTagOption
    }

    const onShowModalNewTagName = () => {
      onShowAddTagOption()
      state.isShowModalNewTag = !state.isShowModalNewTag
    }

    const onCloseModalNewTagName = () => {
      state.isShowModalNewTag = false
      state.newTagName = ''
    }

    const onEntry = async () => {
      if (isServer) return
      if (!await sessionCheck(route.fullPath)) return

      const pidList: number[] = []
      // アイテム行ごとのチェックボックス状態を取得
      const checkBoxCheckedTags = document.querySelectorAll<HTMLInputElement>('input[name=multiple-add-tag]:checked')
      if (checkBoxCheckedTags.length > 0) {
        checkBoxCheckedTags.forEach(checkBoxCheckedTag => {
          if (checkBoxCheckedTag.checked) pidList.push(Number(checkBoxCheckedTag.value))
        })
      } else {
        pidList.push(props.pid)
      }

      // サムネイル表示の場合ではコンテンツ単位でもチェック可能となることから、
      // 同じPIDがpidListに重複格納されるため、重複排除
      const pidSet = new Set(pidList)
      const pidListOfWithoutDuplicates = Array.from(pidSet)

      await store.dispatch('mycollectionCollectionEntry', {
        tagName: state.newTagName,
        pidList: pidListOfWithoutDuplicates,
      })
      onCloseModalNewTagName()
      state.newTagName = ''
      context.emit('update')
    }

    const onLink = async (tagId: number) => {
      if (isServer) return
      if (!await sessionCheck(route.fullPath)) return

      const pidList: number[] = []
      // アイテム行ごとのチェックボックス状態を取得
      const checkBoxCheckedTags = document.querySelectorAll<HTMLInputElement>('input[name=multiple-add-tag]:checked')
      if (checkBoxCheckedTags.length > 0) {
        checkBoxCheckedTags.forEach(checkBoxCheckedTag => {
          if (checkBoxCheckedTag.checked) pidList.push(Number(checkBoxCheckedTag.value))
        })
      } else {
        pidList.push(props.pid)
      }

      // サムネイル表示の場合ではコンテンツ単位でもチェック可能となることから、
      // 同じPIDがpidListに重複格納されるため、重複排除
      const pidSet = new Set(pidList)
      const pidListOfWithoutDuplicates = Array.from(pidSet)

      await store.dispatch('myCollectionTagLink', {
        tagId: tagId,
        pidList: pidListOfWithoutDuplicates,
      })
      onShowAddTagOption()
      context.emit('update')
    }

    const onUnlink = async (tagId: number) => {
      if (!await sessionCheck(route.fullPath)) return
      store.dispatch('myCollectionItemUnlink', {
        tagId: tagId,
        pid: props.pid,
        bid: null,
        cid: null,
      })
      onShowAddTagOption()
      context.emit('update')
    }

    const isShowAddTagOption = computed(() => {
      return state.isShowAddTagOption
    })

    const isShowModalNewTag = computed(() => {
      return state.isShowModalNewTag
    })

    const tagList = computed(() => {
      const selectedTagList = props.selectedTag
      return _.reduce(
        store.getters.myCollectionTagList,
        (res: any[], tagItem) => {
          const isActive = _.find(selectedTagList, (tagId) => tagId === tagItem.id) || false
          const optionParam: any = {
            id: tagItem.id,
            label: tagItem.label,
            hit: tagItem.hit,
            isActive,
          }
          res.push(optionParam)
          return res
        }, [])
    })

    const checkDisabledButtonEntry = (newTagName: string) => {
      if (newTagName.length > 100 || newTagName.length <= 0) return true
      if (newTagName === ' ' || newTagName === '　') return true
      return false
    }

    return {
      state,
      addTagDropdownOption,
      onShowAddTagOption,
      onShowModalNewTagName,
      onCloseModalNewTagName,
      onEntry,
      onLink,
      onUnlink,
      checkDisabledButtonEntry,
      isShowAddTagOption,
      isShowModalNewTag,
      tagList,
    }
  },
})
