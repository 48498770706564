
import { defineComponent, computed, onMounted, onBeforeMount, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import TheSearchResultMain from '@/components/organisms/TheSearchResultMain.vue'
import TheMyCollectionMain from '@/components/organisms/TheMyCollectionMain.vue'
import TheSearchImageResultMain from '@/components/organisms/TheSearchImageResultMain.vue'
import TheMyCollectionLeftColumn from '@/components/organisms/TheMyCollectionLeftColumn.vue'
import LoadingView from '@/components/molecules/LoadingView.vue'
import { sessionCheck } from '@/domain/session/sessionCheck'

export default defineComponent({
  name: 'MyCollection',
  components: {
    TheSearchResultMain,
    TheMyCollectionMain,
    TheSearchImageResultMain,
    TheMyCollectionLeftColumn,
    LoadingView,
  },
  props: {
    component: {
      type: String,
      required: true,
    },
    collection: {
      type: Object,
      required: false,
    },
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const result = computed(() => store.getters.ItemList)
    const isProcessing = computed(() => store.getters.myCollectionIsProcessing)
    const isLoggedIn = computed(() => store.getters.isLoggedIn)

    onBeforeMount(() => {
      if (!isLoggedIn.value) {
        store.commit('NotLoggedIn', true)
        router.push({ path: '/' })
      }
    })

    onMounted(() => {
      store.commit('MY_COLLECTION_SELECTED_TAG', route.query.tagId)
      store.commit('MY_COLLECTION_KEY_WORD', route.query.keywords)
      store.dispatch('myCollectionSelectSortKey', route.query.sortKey)
      store.dispatch('myCollectionSelectPageNumber', route.query.pageNum)
      store.dispatch('myCollectionSelectDisplayCount', route.query.pageSize)
      store.dispatch('myCollectionSelectDisplayMode', route.query.displayMode)
      store.dispatch('myCollectionSelectDisplayedItemViewPattern', route.query.displayedItemViewPattern)
      if (isLoggedIn.value) {
        search()
      }
    })

    const search = async () => {
      store.dispatch('myCollectionInit')
    }

    watch(route, () => {
      search()
    })

    return {
      result,
      isProcessing,
      showModal: ref(false),
    }
  },
})
