
import { defineComponent, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import TheMyCollectionItemListView from './TheMyCollectionItemListView.vue'
import TheMyCollectionLeftColumn from '@/components/organisms/TheMyCollectionLeftColumn.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import AppCheckbox from '@/components/atoms/AppCheckbox.vue'
import AppSelect from '@/components/atoms/AppSelect.vue'
import KeywordSearch from '@/components/molecules/KeywordSearch.vue'
import PageNavigation from '@/components/molecules/PageNavigation.vue'
import { useI18n } from 'vue-i18n'
import { displayModeList } from '@/data/selector/displayModeList'
import { displayContentsList } from '@/data/selector/displayContentsList'
import { mycollectionSortList } from '@/data/selector/mycollectionSortList'
import { getSelector } from '@/data/selector/getSelector'
import { displayList } from '@/data/selector/displayList'
import toLocale from '@/helpers/util/toLocale'
import ModalSheetMobile from '@/components/organisms/ModalSheetMobile.vue'
import CloserController from '@/components/molecules/CloserController.vue'
import RadioButtonList from '@/components/molecules/RadioButtonList.vue'
import CollectionDropdown from './TheMyCollectionCollectionDropdown.vue'

export default defineComponent({
  components: {
    TheMyCollectionItemListView,
    TheMyCollectionLeftColumn,
    AppButton,
    AppCheckbox,
    AppSelect,
    KeywordSearch,
    PageNavigation,
    ModalSheetMobile,
    CloserController,
    RadioButtonList,
    CollectionDropdown,
  },
  props: {},
  setup (props, context) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n()
    const lang = i18n.locale
    const myCollectionSearchMode = computed(() => store.getters.myCollectionSearchMode)

    const VIEW_TYPE_LIST_ITEM = 'list'
    const VIEW_TYPE_THUMBNAIL = 'thumbnail'

    const getDisplayedItemViewPattern = computed(() => {
      if (!myCollectionSearchMode.value) return true
      if (String(myCollectionSearchMode.value.myColletionDisplayItemViewPattern).toLowerCase() === 'true') return true
      if (String(myCollectionSearchMode.value.myColletionDisplayItemViewPattern).toLowerCase() === 'false') return false
      return true
    })

    // TODO refactor ページネーション対応時にsearchResultState.tsへ色々移管する
    const state = reactive({
      keywords: store.getters.myCollectionKeyword,
      displayMode: myCollectionSearchMode.value ? myCollectionSearchMode.value.myColletionDisplayMode : VIEW_TYPE_LIST_ITEM,
      displayedItemViewPattern: getDisplayedItemViewPattern.value,
      displayedItemCount: myCollectionSearchMode.value ? Number(myCollectionSearchMode.value.myColletionPageSize) : 20,
      sort: myCollectionSearchMode.value ? myCollectionSearchMode.value.myColletionSortKey : 'SCORE',
      showModal: false,
      showSortList: true,
      showDisplayList: true,
    })

    const isServer = typeof window === 'undefined'

    // アイテム表示スタイル選択
    const displayModeLocale = computed(() => getSelector('displayModeList', displayModeList, i18n.t))
    const displayMode = computed({
      get: () => state.displayMode,
      set: (val) => {
        store.dispatch('myCollectionSelectDisplayMode', val)
        router.push({ query: { ...route.query, displayMode: val } })
      },
    })

    // コンテンツ表示／非表示
    const displayItemViewPatternList = computed(() => getSelector('displayContentsList', displayContentsList, i18n.t))
    const displayedItemViewPattern = computed({
      get: () => state.displayedItemViewPattern,
      set: (val) => {
        store.dispatch('myCollectionSelectDisplayedItemViewPattern', String(val))
        router.push({ query: { ...route.query, displayedItemViewPattern: String(val) } })
      },
    })

    const sortList = computed(() => getSelector('mycollectionSortList', mycollectionSortList, i18n.t))

    const selectedTag = computed(() => store.getters.myCollectionSelectedTag)

    const sort = computed({
      get: () => state.sort,
      set: (val) => {
        store.dispatch('myCollectionSelectSortKey', val)
        state.showModal = false
        router.push({ query: { ...route.query, sortKey: val, pageNum: 0 } })
      },
    })

    const displayLocale = computed(() => getSelector('displayList', displayList, i18n.t))
    const displayedItemCount = computed({
      get: () => state.displayedItemCount,
      set: (val) => {
        store.dispatch('myCollectionSelectDisplayCount', Number(val))
        store.dispatch('myCollectionSelectPageNumber', 0)
        state.showModal = false
        router.push({ query: { ...route.query, pageSize: val, pageNum: 0 } })
      },
    })

    const pageNumber = computed({
      get: () => store.getters.myCollectionPageNumber,
      set: (val) => {
        store.dispatch('myCollectionSelectPageNumber', val)
        router.push({ query: { ...route.query, pageNum: val } })
      },
    })
    const pageNavigationIndex = computed(() => {
      const pageCount = Math.ceil(Math.min(store.getters.myCollectionItemCount, 1000) / displayedItemCount.value)
      return Array(pageCount).fill(0).map((_, i) => (i + 1).toString())
    })

    const total = computed(() => {
      return store.getters.myCollectionItemCount
    })
    const start = computed(() => {
      return pageNumber.value * displayedItemCount.value
    })
    const end = computed(() => {
      // (仮決め)201件ヒット時想定外のページ指定したときに'221-201'ではなく'221-240'とだすため
      if (start.value > store.getters.myCollectionItemCount) {
        return start.value + displayedItemCount.value
      }
      return Math.min(start.value + displayedItemCount.value, store.getters.myCollectionItemCount)
    })

    const hasError = computed(() => {
      // return store.getters.hasErrorInSearchResultPage
      return false
    })
    const errorMessage = computed(() => {
      // return store.getters.errorTextInSearchResultPage
      return false
    })

    // displayModeによってマイコレクション表示用Objectを出しわけする
    const myCollections = computed(() => {
      const item: string = VIEW_TYPE_LIST_ITEM
      const thumbnail: string = VIEW_TYPE_THUMBNAIL

      return {
        [item]: store.getters.myCollectionList,
        [thumbnail]: store.getters.myCollectionThumbnail,
      }[displayMode.value]
    })
    const myCollectionItemCount = computed(() => store.getters.myCollectionItemCount)

    const removeList = computed(() => {
      const list = store.state.myCollectionInfo.removeList
      return list
    })

    const isDisplayItem = (item: any) => {
      if (displayMode.value === VIEW_TYPE_LIST_ITEM) return true
      if (displayedItemViewPattern.value) return true

      return item.isItem || false
    }

    const tagSelected = () => { state.showModal = false }

    const showModal = () => {
      context.emit('showModal')
      state.showModal = true
      if (!isServer) {
        const focusElement = document.getElementById('modal-focus-target')
        if (focusElement) {
          focusElement.focus()
        }
      }
    }

    const search = () => {
      store.commit('MY_COLLECTION_KEY_WORD', state.keywords)
      router.push({ query: { ...route.query, keywords: state.keywords, pageNum: 0 } })
    }

    const isShowCollectionDropdown = computed(() => store.getters.myCollectionIsShowCollectionDropdown)

    const onShowChangedCollectionDropdown = () => {
      if (isServer) return

      // すべて選択するチェックボックスの存在チェック
      const allChecked: HTMLInputElement | null = document.querySelector('input[id=multiple-add-tag-all]')
      if (!allChecked) {
        return
      }
      // アイテム行ごとのチェックボックス状態を連動して変更
      const checkBoxTags = document.querySelectorAll<HTMLInputElement>('input[name=multiple-add-tag]')
      checkBoxTags.forEach(checkBoxTag => { checkBoxTag.checked = allChecked.checked })
      // コレクションに追加ボタン状態を連動して変更
      store.dispatch('myCollectionShowCollectionDropdown', allChecked.checked)
    }

    onMounted(() => {
      // 画面表示時は表示状態をリセット
      store.dispatch('myCollectionShowCollectionDropdown', false)
    })

    return {
      VIEW_TYPE_LIST_ITEM,
      VIEW_TYPE_THUMBNAIL,
      search,
      pageNavigationIndex,
      pageNumber,
      total,
      start,
      end,
      myCollections,
      myCollectionItemCount,
      state,
      selectedTag,
      displayModeLocale,
      displayMode,
      displayItemViewPatternList,
      displayedItemViewPattern,
      removeList,
      sortList,
      sort,
      hasError,
      errorMessage,
      isDisplayItem,
      toLocale,
      lang,
      tagSelected,
      displayLocale,
      displayedItemCount,
      showModal,
      isShowCollectionDropdown,
      onShowChangedCollectionDropdown,
    }
  },
})
