import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, Teleport as _Teleport, renderList as _renderList, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23e9a132"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-search-result-main" }
const _hoisted_2 = { class: "the-search-result-main-header" }
const _hoisted_3 = {
  key: 0,
  class: "myCollection-header"
}
const _hoisted_4 = { class: "main-head" }
const _hoisted_5 = { class: "keywordSearch-wrap" }
const _hoisted_6 = {
  key: 0,
  class: "the-search-result-main-total",
  id: "search-result-total-hits-message"
}
const _hoisted_7 = {
  key: 0,
  class: "has-text-title has-text-contents-title",
  id: "search-result-total-hits-message"
}
const _hoisted_8 = {
  "aria-hidden": "true",
  id: "itemTotalHits"
}
const _hoisted_9 = { class: "is-sr-only" }
const _hoisted_10 = {
  key: 0,
  id: "error-message"
}
const _hoisted_11 = { class: "the-search-result-main-show-facet level-right is-mobile is-hidden-desktop has-background-contents" }
const _hoisted_12 = { class: "the-search-result-main-nav is-hidden-mobile nav-common has-background-contents" }
const _hoisted_13 = { class: "nav-left" }
const _hoisted_14 = {
  key: 0,
  class: "tag-list-view"
}
const _hoisted_15 = { class: "tag-list" }
const _hoisted_16 = { class: "tag-item" }
const _hoisted_17 = { class: "nav-right" }
const _hoisted_18 = { class: "modal-my-collection-sorting" }
const _hoisted_19 = { class: "the-search-result-main-nav-mobile" }
const _hoisted_20 = { class: "has-background-contents shadow" }
const _hoisted_21 = { class: "has-text-table-cell-title" }
const _hoisted_22 = { class: "has-background-contents shadow" }
const _hoisted_23 = { class: "has-text-table-cell-title" }
const _hoisted_24 = { class: "modal-wrapp modal-sheet-mobile" }
const _hoisted_25 = {
  key: 1,
  class: "the-search-result-main-item-list"
}
const _hoisted_26 = {
  key: 1,
  class: "the-search-result-main-pager"
}
const _hoisted_27 = {
  key: 2,
  class: "search-not-found"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_KeywordSearch = _resolveComponent("KeywordSearch")!
  const _component_AppCheckbox = _resolveComponent("AppCheckbox")!
  const _component_CollectionDropdown = _resolveComponent("CollectionDropdown")!
  const _component_AppSelect = _resolveComponent("AppSelect")!
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_RadioButtonList = _resolveComponent("RadioButtonList")!
  const _component_TheMyCollectionLeftColumn = _resolveComponent("TheMyCollectionLeftColumn")!
  const _component_ModalSheetMobile = _resolveComponent("ModalSheetMobile")!
  const _component_TheMyCollectionItemListView = _resolveComponent("TheMyCollectionItemListView")!
  const _component_PageNavigation = _resolveComponent("PageNavigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.selectedTag)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_DssIcon, { icon: 'bookmark-fill' }),
            (_ctx.selectedTag.id === 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('label.all')), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.selectedTag.label), 1)
                ], 64))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_KeywordSearch, {
            placeholder: _ctx.$t('label.keyword'),
            class: "facet-item-keyword",
            modelValue: _ctx.state.keywords,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.keywords) = $event)),
            label: _ctx.$t('label.keyword')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AppButton, {
                styled: "is-none",
                type: "submit",
                label: _ctx.$t('parts.search'),
                onClick: _ctx.search,
                onKeydown: _withKeys(_withModifiers(_ctx.search, ["prevent"]), ["space","enter"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('parts.search')), 1)
                ]),
                _: 1
              }, 8, ["label", "onClick", "onKeydown"])
            ]),
            _: 1
          }, 8, ["placeholder", "modelValue", "label"])
        ]),
        (!_ctx.hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_ctx.myCollectionItemCount > 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('label.searchResult') + ' '), 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('label.result' , { start: _ctx.toLocale(_ctx.start + 1, _ctx.lang), end: _ctx.toLocale(_ctx.end, _ctx.lang), total: _ctx.toLocale(_ctx.total, _ctx.lang) })), 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('label.resultScreenReader' , { start: _ctx.toLocale(_ctx.start + 1, _ctx.lang), end: _ctx.toLocale(_ctx.end, _ctx.lang), total: _ctx.toLocale(_ctx.total, _ctx.lang) })), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.errorMessage), 1))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.myCollectionItemCount > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["the-search-result-main-posts", {
          'list-view': _ctx.displayMode === _ctx.VIEW_TYPE_LIST_ITEM,
          'thumbnail-view': _ctx.displayMode === _ctx.VIEW_TYPE_THUMBNAIL
        }])
              }, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_AppButton, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal())),
                    onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.showModal()), ["prevent"]), ["space","enter"])),
                    class: "button-normal the-app-button-my-collection-sorting",
                    styled: "is-normal",
                    id: "sorting-button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('form.sorting')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("nav", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_AppCheckbox, {
                      id: "multiple-add-tag-all",
                      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onShowChangedCollectionDropdown())),
                      "aria-label": _ctx.$t('mycollection.tag.checkAll')
                    }, null, 8, ["aria-label"]),
                    (_ctx.isShowCollectionDropdown)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createElementVNode("ul", _hoisted_15, [
                            _createElementVNode("li", _hoisted_16, [
                              _createVNode(_component_CollectionDropdown, { styled: 'is-none' })
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_AppSelect, {
                      modelValue: _ctx.displayMode,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.displayMode) = $event)),
                      options: _ctx.displayModeLocale,
                      componentId: "the-selectbox-display-mode"
                    }, null, 8, ["modelValue", "options"]),
                    _createVNode(_component_AppSelect, {
                      modelValue: _ctx.displayedItemViewPattern,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.displayedItemViewPattern) = $event)),
                      options: _ctx.displayItemViewPatternList,
                      componentId: "the-selectbox-contents-show"
                    }, null, 8, ["modelValue", "options"]),
                    _createVNode(_component_AppSelect, {
                      modelValue: _ctx.displayedItemCount,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.displayedItemCount) = $event)),
                      options: _ctx.displayLocale,
                      componentId: "the-selectbox-page-size"
                    }, null, 8, ["modelValue", "options"]),
                    _createVNode(_component_AppSelect, {
                      modelValue: _ctx.sort,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sort) = $event)),
                      options: _ctx.sortList,
                      componentId: "the-selectbox-sort-key"
                    }, null, 8, ["modelValue", "options"])
                  ])
                ]),
                (_ctx.state.showModal)
                  ? (_openBlock(), _createBlock(_Teleport, {
                      key: 0,
                      to: "#mobile-mycollection-menu"
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_ModalSheetMobile, {
                          show: _ctx.state.showModal,
                          onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.state.showModal = false)),
                          title: _ctx.$t('form.sorting')
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("section", _hoisted_20, [
                                _createVNode(_component_CloserController, {
                                  modelValue: _ctx.state.showDisplayList,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.showDisplayList) = $event)),
                                  icons: ['accordion-open', 'arrow-right'],
                                  class: "has-background-modest"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("h2", _hoisted_21, _toDisplayString(_ctx.$t('form.displayNum')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"]),
                                (_ctx.state.showDisplayList)
                                  ? (_openBlock(), _createBlock(_component_RadioButtonList, {
                                      key: 0,
                                      modelValue: _ctx.displayedItemCount,
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.displayedItemCount) = $event)),
                                      items: _ctx.displayLocale.map((item) => ({ label: item.name, value: item.value })),
                                      id: "display-num",
                                      name: "display-num",
                                      mobileFlag: true
                                    }, null, 8, ["modelValue", "items"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("section", _hoisted_22, [
                                _createVNode(_component_CloserController, {
                                  modelValue: _ctx.state.showSortList,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.showSortList) = $event)),
                                  icons: ['accordion-open', 'arrow-right'],
                                  class: "has-background-modest"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("h2", _hoisted_23, _toDisplayString(_ctx.$t('form.order')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"]),
                                (_ctx.state.showSortList)
                                  ? (_openBlock(), _createBlock(_component_RadioButtonList, {
                                      key: 0,
                                      modelValue: _ctx.sort,
                                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.sort) = $event)),
                                      items: _ctx.sortList.map((item) => ({ label: item.name, value: item.value })),
                                      class: "has-text-contents-normal has-form-text-normal",
                                      id: "sort-type",
                                      name: "sort-type",
                                      mobileFlag: true
                                    }, null, 8, ["modelValue", "items"]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_24, [
                              _createVNode(_component_TheMyCollectionLeftColumn, {
                                class: "search-result-side",
                                onTagSelected: _ctx.tagSelected
                              }, null, 8, ["onTagSelected"])
                            ])
                          ]),
                          _: 1
                        }, 8, ["show", "title"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.myCollectionItemCount > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myCollections, (item, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          (_ctx.isDisplayItem(item))
                            ? (_openBlock(), _createBlock(_component_TheMyCollectionItemListView, {
                                class: "the-search-result-main-item-article",
                                tag: "article",
                                index: index,
                                item: item,
                                isItem: item.isItem || false,
                                displayMode: _ctx.displayMode,
                                contentListViewHandler: _ctx.displayedItemViewPattern,
                                key: `TheMyCollectionItemListView_${item.pid}_${index}`
                              }, null, 8, ["index", "item", "isItem", "displayMode", "contentListViewHandler"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 256))
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.myCollectionItemCount > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _createVNode(_component_PageNavigation, {
                  modelValue: _ctx.pageNumber,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.pageNumber) = $event)),
                  displayCount: 5,
                  items: _ctx.pageNavigationIndex
                }, null, 8, ["modelValue", "items"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.notFound')), 1)
              ]))
        ], 64))
  ]))
}