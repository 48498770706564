import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b8614bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "the-my-collection-left-column",
  class: "the-search-result-left-column"
}
const _hoisted_2 = { class: "the-my-collection-left-column-header level is-mobile" }
const _hoisted_3 = { class: "is-hidden-desktop has-text-contents-title has-text-modest" }
const _hoisted_4 = { class: "facet-panel" }
const _hoisted_5 = { class: "facet-panel-header has-background-modest" }
const _hoisted_6 = { class: "facet-panel-header-label" }
const _hoisted_7 = { class: "facet-panel-items has-background-contents" }
const _hoisted_8 = ["onClick", "onKeydown"]
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "hit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('parts.facetFiltering')), 1)
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("header", _hoisted_5, [
        _createElementVNode("h1", _hoisted_6, [
          _createVNode(_component_DssIcon, { icon: "bookmark-fill" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('label.myCollection')), 1)
        ])
      ]),
      _createElementVNode("ul", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagList, (tag, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: 'tag-list-' + index,
            class: _normalizeClass({ selected: _ctx.tagSelected.id === tag.id})
          }, [
            _createElementVNode("button", {
              class: "button-collection-item has-text-normal level is-mobile",
              styled: "is-none",
              onClick: ($event: any) => (_ctx.onTagSelected(tag.id)),
              onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.onTagSelected(tag.id)), ["prevent"]), ["space","enter"])
            }, [
              _createElementVNode("span", _hoisted_9, [
                (tag.id === '0')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('label.all')), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(tag.label), 1)
                    ], 64))
              ]),
              _createElementVNode("span", _hoisted_10, _toDisplayString(tag.hit), 1)
            ], 40, _hoisted_8)
          ], 2))
        }), 128))
      ])
    ])
  ]))
}